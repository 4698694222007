<template>
  <div class="project-item-root-container">
    <my-avatar
      :size="50"
      :name="dataSource.name"
      :img_url="dataSource.img_url"
    />
    <span
      style="
        font-size: 23px;
        font-weight: bold;
        margin-top: 20px;
        max-width: 200px;
        text-align: center;
      "
      >{{ dataSource.name }}</span
    >
    <p style="font-size: 12px; margin-top: 30px">{{ dataSource.more }}</p>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
};
</script>

<style scoped>
.project-item-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  height: 380px;
  min-width: 330px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 0px 0px 2px #dddddd;
  margin: 10px;
  margin-right: 50px;
  cursor: pointer;
}
.project-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
</style>