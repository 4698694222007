<template>
  <div class="company-base-root-container">
    <my-avatar
      style="cursor: pointer"
      :size="50"
      :name="baseInfo.name"
      :img_url="baseInfo.img_url"
    />
    <span class="company-base-name">{{ baseInfo.name }}</span>
    <div class="company-base-row" v-if="baseInfo.state == 2">
      <img :src="icons.pass" style="width: 15px; height: 17px" />
      <span style="font-size: 12px; color: #2b2b2b; margin-left: 10px"
        >已通过企业认证</span
      >
    </div>
    <div class="company-base-row" v-else>
      <img :src="icons.warning" style="width: 13px; height: 15px" />
      <span
        style="
          font-size: 12px;
          color: #808080;
          margin-left: 10px;
          margin-right: 10px;
        "
        >未完成企业认证</span
      >
    </div>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
import * as icon from "../../../common/icon";
import { mapActions } from "vuex";
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
      company_id: -1,
      baseInfo: {
        img_url: null,
        name: "",
        state: 2,
      },
    };
  },
  mounted() {
    this.company_id = this.$route.params.id;

    this.getCompanyBasic();
  },
  methods: {
    ...mapActions({
      companyGetCompanyBasicAction: "companyGetCompanyBasic",
    }),
    getCompanyBasic() {
      this.companyGetCompanyBasicAction({
        company_id: this.company_id,
      })
        .then((res) => {
          this.baseInfo = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.company-base-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 14px;
}
.company-base-name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
}
.company-base-row {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>