<template>
  <div class="company-trade-root-container">
    <div class="company-trade-row">
      <span style="font-size: 23px; font-weight: bold; color: #343733"
        >对平台的付款记录</span
      >
      <span
        style="
          font-size: 13px;
          font-weight: bold;
          color: #587bea;
          cursor: pointer;
        "
        @click="showTradeModal"
        >展示全部交易</span
      >
    </div>
    <div>
      <trade-item
        v-for="item in tradeList"
        :key="item.trade_id"
        :data-source="item"
      />
    </div>
    <search-result-modal ref="searchResultModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import TradeItem from "../../Contact/Item/TradeItem";
import SearchResultModal from "../../Home/Modal/SearchResultModal";
export default {
  components: {
    "trade-item": TradeItem,
    "search-result-modal": SearchResultModal,
  },
  data() {
    return {
      company_id: 0,
      tradeList: [],
      baseInfo: {},
    };
  },
  mounted() {
    this.company_id = this.$route.params.id;
    this.getCompanyBasic();
    this.getTradeList();
  },
  methods: {
    ...mapActions({
      companyGetTradeListAction: "companyGetTradeList",
      companyGetCompanyBasicAction: "companyGetCompanyBasic",
    }),
    getCompanyBasic() {
      this.companyGetCompanyBasicAction({
        company_id: this.company_id,
      })
        .then((res) => {
          this.baseInfo = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getTradeList() {
      this.companyGetTradeListAction({
        company_id: this.company_id,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.tradeList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    showTradeModal() {
      this.$refs.searchResultModal.open(
        {
          type: 2,
          search_content: this.company_id,
          search_content_format: this.baseInfo.name,
          start_time: "Invalid date",
          end_time: "Invalid date",
        },
        0
      );
    },
  },
};
</script>

<style scoped>
.company-trade-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.company-trade-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>