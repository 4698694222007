<template>
  <div class="company-project-root-container">
    <span style="color: #343733; font-size: 23px; font-weight: bold"
      >已创建的项目</span
    >
    <div v-for="item in project_list" :key="item.project_id">
      <project-item :data-source="item" @click.native="gotoProject(item)" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProjectItem from "../Item/ProjectItem";
export default {
  data() {
    return {
      company_id: 0,
      project_list: [],
    };
  },
  components: {
    "project-item": ProjectItem,
  },
  mounted() {
    this.company_id = this.$route.params.id;

    this.companyGetProjectListAction({
      company_id: this.company_id,
    })
      .then((res) => {
        this.project_list = res;
      })
      .catch((error) => {
        console.error(error);
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      companyGetProjectListAction: "companyGetProjectList",
    }),
    gotoProject(item) {
      this.$router.push({
        name: "project-base",
        params: {
          id: item.project_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.company-project-root-container {
  display: flex;
  flex-direction: column;
}
</style>