<template>
  <div class="employer-company-root-container">
    <div
      class="employer-company-background"
      :style="{ 'background-image': 'url(' + icons.employerBackground + ')' }"
    ></div>
    <div class="employer-company-scroll-wrapper">
      <div class="employer-company-left-container">
        <company-base />
        <company-service style="margin-top: 60px" />
        <company-project style="margin-top: 60px" />
      </div>
      <div class="employer-company-right-container">
        <company-business />
        <company-trade style="margin-top: 30px" />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyBusiness from "./Panel/CompanyBusiness";
import CompanyService from "./Panel/CompanyService";
import CompanyProject from "./Panel/CompanyProject";
import CompanyBase from "./Panel/CompanyBase";
import CompanyTrade from "./Panel/CompanyTrade";
import * as icon from "../../common/icon";
export default {
  components: {
    "company-service": CompanyService,
    "company-project": CompanyProject,
    "company-base": CompanyBase,
    "company-business": CompanyBusiness,
    "company-trade": CompanyTrade,
  },
  data() {
    return {
      icons: icon,
    };
  },
  mounted() {},
};
</script>

<style scoped>
.employer-company-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px 115px 20px 115px;
  background-color: #f7f7f7;
}
.employer-company-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 340px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.employer-company-scroll-wrapper {
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  z-index: 2;
}
.employer-company-left-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 340px;
  margin-right: 60px;
}
.employer-company-right-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}
</style>